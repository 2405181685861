.titulo{
    align-items: center;
    font-weight: bold;
    text-align: center;
    font-size: 2em;
}
.shadows{
    box-shadow: 0px 1px 13px 4px #7D7A7A;
    width: 100%;
    height: 68vh;
}