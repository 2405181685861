.sidebar .selector {
  color: #000000;
}

.contenedor {
  width: 100%;
  height: 100%;
}

.sidebar {
  
  height: 73%;
  margin-top: 151px;
  transition: all 500ms linear;
}
