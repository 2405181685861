.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
.estilo{
    padding-left: 2.5rem; 
    padding-right: 2.5rem;
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
