.shadowsM{
    box-shadow: 0px 1px 13px 4px #7D7A7A;
    width: 100%;
    height: 75vh;
    padding-top: 10px;
}

.leaflet-container {
    width: 100%;
    height: 98%;
    padding-top: 5px;
    margin-top: 5px;
    padding-bottom: auto;
}