
* {
    box-sizing: border-box !important;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #666666;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.80857;
    font-weight: normal;
}

a {
    color: #1f1f1f;
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 0;
    font-weight: normal;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: normal;
    line-height: normal;
    color: #111111;
    margin: 0
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 13px
}

*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #212121;
    text-decoration: none!important;
    opacity: 1
}

button:focus {
    outline: none;
}

ul,
li,
ol {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

p {
    margin: 0px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
}

a {
    color: #222222;
    text-decoration: none;
    outline: none !important;
}

a,
.btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

img {
    max-width: 100%;
    height: auto;
}

:focus {
    outline: 0;
}

.btn-custom {
    margin-top: 20px;
    background-color: transparent !important;
    border: 2px solid #ddd;
    padding: 12px 40px;
    font-size: 16px;
}

.lead {
    font-size: 18px;
    line-height: 30px;
    color: #767676;
    margin: 0;
    padding: 0;
}

.form-control:focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
    border: none !important;
}

.badge {
    font-weight: 500;
}

blockquote {
    margin: 20px 0 20px;
    padding: 30px;
}

button {
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.full {
    float: left;
    width: 100%;
}

.layout_padding {
    padding-top: 90px;
    padding-bottom: 90px;
}

.layout_padding_2 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.light_silver {
    background: #f9f9f9;
}

.theme_bg {
    background: #38c8a8;
}

.margin_top_30 {
    margin-top: 30px !important;
}

.full {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
}


.loader_bg {
    position: fixed;
    z-index: 9999999;
    background: #fff;
    width: 100%;
    height: 100%;
}

.loader {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader img {
    width: 280px;
}
/*-- navigation--*/

.navigation.navbar {
    float: right;
    padding: 27px;
}

.navigation.navbar-dark .navbar-nav .nav-link {
    padding: 0px 20px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

.navigation.navbar-dark .navbar-nav .nav-link:focus,
.navigation.navbar-dark .navbar-nav .nav-link:hover {
    color: #000;
}

.navigation.navbar-dark .navbar-nav .active>.nav-link,
.navigation.navbar-dark .navbar-nav .nav-link.active,
.navigation.navbar-dark .navbar-nav .nav-link.show,
.navigation.navbar-dark .navbar-nav .show>.nav-link {
    color: #000;
}

.navbar-expand-md .navbar-nav {
    padding-right: 10px;
}

.header_information {
    background-color: #00aeef;
    padding: 0 0;
    padding-top: 0px;
    padding-top: 0px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    /*position: absolute;*/
    width: 100%;
    height: 98px;
    margin-top: -20px;
    right: 0;
}

.header_information::before {
    width: 84px;
    height: 98px;
    background-repeat: repeat;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0px;
    background-repeat: no-repeat;
}

.sign_btn {
    padding-left: 50px;
}

.sign_btn a {
    background-color: #fff;
    display: inline-block;
    padding: 7px 35px;
    border-radius: 20px;
    color: #292e39;
    font-size: 17px;
}

.sign_btn a:hover {
    color: #fff;
    background: #000;
}


/*-- header area --*/

.header {
    width: 100%;
    background: #fff;
    padding: 20px 0px;
}

.logo a {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    line-height: 40px;
    
    z-index: 5px;
}

/** banner section **/
.titlepage {
    text-align: center;
    padding-bottom: 60px;
}

.titlepage h2 {
    font-size: 45px;
    color: #0b0503;
    line-height: 50px;
    font-weight: bold;
    padding: 0;
}

.titlepage span {
    background: #00a8e7;
    display: block;
    height: 10px;
    width: 90px;
}

.d_flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.read_more {
    font-size: 17px;
    background-color: transparent;
    color: #7a7a7a;
    padding: 8px 0px;
    width: 100%;
    max-width: 190px;
    text-align: center;
    display: inline-block;
    border-radius: 30px;
    border: #00aaea solid 3px;
    transition: ease-in all 0.5s;
}

.read_more i {
    padding-left: 10px;
}

.read_more:hover {
    background: #00aaea;
    color: #fff !important;
    transition: ease-in all 0.5s;
}

/** about section **/

.about {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 90px 0;
}

.about .titlepage {
    text-align: left;
    padding: 0;
}

.about .titlepage p {
    color: #292e39;
    font-size: 17px;
    line-height: 35px;
    font-weight: 500;
    padding: 20px 0px 35px 0px;
}

.about_img figure {
    margin: 0;
}

.about_img figure img {
    width: 100%;
}


/** end about section **/

/** mobile section **/
#mobile{
    background-color: #345B7C;
}
.mobile {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 90px 0;
}

.mobile .titlepage {
    text-align: right;
    padding: 0;
}

.mobile .titlepage h2 {
    color: #fff;
}

.mobile .titlepage span {
    margin-left: 58px;
}

.mobile .titlepage p {
    color: #fff;
    font-size: 17px;
    line-height: 35px;
    font-weight: 500;
    padding: 20px 0px 35px 0px;
}

.mobile_img figure {
    margin: 0;
}

.mobile_img figure img {
    width: 100%;
}

.mobile .titlepage .read_more {
    border: #fff solid 3px;
    color: #fff;
}

/** clients section **/

.clients {
    margin-top: 40px;
    padding-top: 60px;
}

.clients .titlepage h2 {
    color: #202021;
}

.clients .titlepage span {
    margin-left: 56px;
}

.clients_box {
    text-align: center;
    background: #e2e3e3;
    padding: 50px 15px;
    border-radius: 15px;
    position: relative;
}

.clients_box p {
    font-size: 17px;
    line-height: 35px;
}

.clients_box::after {
    position: absolute;
    content: "";
    width: 42px;
    height: 67px;
    bottom: -65px;
    background-repeat: no-repeat;
}

.jonu {
    padding-top: 72px;
    text-align: center;
}

.jonu h3 {
    padding: 0px 0px 0px 0px;
    color: #0b0503;
    font-size: 25px;
}

.jonu strong {
    display: block;
    color: #00aeef;
    font-size: 17px;
    padding-bottom: 50px;
}


/** end clients section **/
/** contact section **/

.contact {
    background: #042431;
    margin-top: 90px;
    padding: 90px 0;
}

.contact .titlepage h2 {
    color: #fff;
}

.main_form .contactus {
    border: inherit;
    margin-bottom: 20px;
    width: 100%;
    height: 60px;
    background: transparent;
    color: #f9fcfd;
    font-size: 18px;
    font-weight: normal;
    border-bottom: #fff solid 1px;
}

.main_form .send_btn {
    font-size: 17px;
    transition: ease-in all 0.5s;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
    border: #00aeef solid 3px;
    color: #f9fcfd;
    padding: 8px 0px;
    margin: 0 auto;
    max-width: 180px;
    width: 100%;
    display: block;
    border-radius: 30px;
    margin-top: 30px;
}

.main_form .send_btn:hover {
    background-color: #00aeef;
    transition: ease-in all 0.5s;
    color: #fff;
}

#request *::placeholder {
    color: #f9fcfd;
    opacity: 1;
}


/** end contact section **/
/** footer **/

.footer {
    background: #042431;
    text-align: center;
}

.border-top {
    border-top: #fff solid 1px;
    padding-top: 90px;
}

.footer h3 {
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-align: left;
    padding-bottom: 20px;
}

.footer_form {
    display: flex;
    border-bottom: #4d636c solid 1px;
    align-items: center;
    justify-content: center;
}

.enter {
    border: inherit;
    width: 71%;
    background: transparent;
    color: #4d636c;
    font-size: 18px;
    font-weight: normal;
    border-bottom: initial;
}

.submit {
    font-size: 17px;
    transition: ease-in all 0.5s;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
    border: #00aeef solid 3px;
    color: #4d636c;
    padding: 5px 0px;
    margin-bottom: 5px;
    max-width: 150px;
    width: 100%;
    display: block;
    border-radius: 30px;
}

.submit:hover {
    background-color: #00aeef;
    transition: ease-in all 0.5s;
    color: #fff;
}

ul.cont li {
    color: #8a9397;
    font-size: 17px;
    line-height: 32px;
    text-align: left;
}

.copyright {
    margin-top: 90px;
    padding: 20px 0px;
    background-color: #00aeef;
}

.copyright p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
}

.copyright a {
    color: #fff;
}

.copyright a:hover {
    color: #000;
}


/** end footer **/


/** inner page css **/

.margin_to90 .footer {
    margin-top: 90px;
}

.ourwork {
    margin: 90px 0 60px 0;
}

.our_box {
    margin-bottom: 30px;
}

.our_box figure {
    margin: 0;
}

.our_box figure img {
    width: 100%;
}