.leaflet-control-scalefactor{
	border: 2px solid #777;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 13px;
	white-space: nowrap;
	overflow: hidden;
	-moz-box-sizing: border-box;
	     box-sizing: border-box;
	color: black;
	background: rgb(255, 254, 254);
	background: rgba(255, 255, 255, 0.5);
	
}

.leaflet-control-scalefactor.leaflet-control{
	margin-left: 50px;
}