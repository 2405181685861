

.itemH{
    font-size: 19px;
}

.userI{
    margin-left: 10px;
    width: 50%;
    height: 50%;
    border: 1px solid #000000;
    border-radius: 25px;
}

#link{
    width: 100px;
}

.userI:hover{
    background-color: #345B7C;
    color:#ffffff
}

.active{
    font-weight: bold;
}
.active2 .userI{
    background-color: #345B7C;
}